.navbar {
  position: fixed;
  z-index: 999;

  .ThemeSwitcher {
    cursor: pointer;
    position: fixed;
    left: 2rem;
    top: 2rem;
    z-index: 10;
  }

  &__home {
    cursor: pointer;
    position: fixed;
    right: 6rem;
    top: 2rem;
    z-index: 10;
  }

  &__pomo {
    cursor: pointer;
    position: fixed;
    right: 6rem;
    top: 2rem;
    z-index: 10;
  }

  &__blog-nav {
    position: fixed;
    padding: 0;
    right: 2rem;
    top: 2rem;
    z-index: 10;
    text-transform: uppercase;
    // transition: all .2s;

    // &:hover,
    // &:active{
    //     &::before{
    //         content: 'blog ';
    //         font-size: 1.9rem;
    //         transition: all .2s;
    //     }
    // }
  }
}
