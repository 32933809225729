$red-primary: #ea4444;
$red-secondary: #ffa8a8;
$grey-light: #dcdcdc;
$grey-dark: #bdbdbd;
$grey-dark2: #a9a9a9;
$grey-focus: #6c757d;
$black: black;

.active {
  color: red !important;
}

.pomodoro {
  max-width: 40rem;
  height: 40rem;
  animation: fadeInAndUp;
  animation-duration: 0.5s;

  margin: 6rem auto;
  font-size: 1.5rem;
  font-family: "DM Sans", sans-serif;
  font-weight: 600;

  &__navbar {
    position: relative;
    height: 6rem;
    margin-bottom: 0.5rem;
    .active {
      background-color: rgba($grey-light, 0.55);
    }

    .disable {
      cursor: not-allowed;
    }
  }

  &__tab {
    background-color: rgba($grey-light, 0.18);
    cursor: pointer;
    padding: 1.8rem 1rem;
    height: 100%;
    width: 32.5%;
    z-index: 999;
    position: absolute;
    &--left {
      left: 0;
      border-radius: 3.1rem 0 0 0;
    }
    &--right {
      right: 0;
      border-radius: 0 3.1rem 0 0;
    }
    &--middle {
      left: 50%;
      transform: translate(-50%);
    }

    &:hover {
      background-color: rgba($grey-light, 0.5);
    }
  }

  &__label {
    font-size: inherit;
    font-weight: inherit;
    text-align: center;
    color: darken($grey-dark2, 0);

    &--large {
      font-size: 3rem;
      color: black;
    }
  }

  &__textarea {
    display: block;
    font-size: 3rem !important;
    color: black !important;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    margin-left: 50%;
    transform: translateX(-50%);
    font-weight: inherit;
    text-align: center;
    color: darken($grey-dark2, 0);
    font-family: inherit;
    resize: none;
    background-color: transparent;
    outline: none;
    font-size: 2rem;
    height: auto;
    border: none;
    overflow: hidden;
  }

  &__action {
    svg {
      fill: darken($grey-dark, 15);
      cursor: pointer;

      &:hover {
        fill: darken($grey-dark, 30);
      }
    }

    &--settings {
      top: 1.3rem;
      right: 1.3rem;
      position: absolute;
      z-index: 999;
    }

    &--audio {
      top: 1.3rem;
      left: 1.3rem;
      position: absolute;
      z-index: 999;
    }

    &--playpause {
      bottom: 1.3rem;
      left: 50%;
      transform: translate(-50%);
      position: absolute;
      z-index: 999;
      path {
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    &--reset {
      text-align: center;
      z-index: 999;
    }
  }

  /* Ellipse 3 */

  &__main {
    height: inherit;
    position: relative;
    background-color: rgba($grey-light, 0.18);
    border-radius: 0 0 3.1rem 3.1rem;

    &__fixedcircle {
      &__svg {
        fill: $red-secondary;
        position: absolute;
        top: -3rem;
        left: 0;
        z-index: 100;
        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
      }
    }

    &__movingcircle {
      /* Ellipse 2 */
      &__svg {
        fill: rgba($color: $red-primary, $alpha: 0.79);
        position: absolute;
        top: -3rem;
        left: 0;
        z-index: 50;
        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
      }
    }

    &__timelabel {
      .pomodoro__label {
        font-size: 5.5rem;
        color: white;
        font-style: normal;
        font-weight: normal;
      }
      z-index: 9999 !important;
      position: absolute;
      top: 32%;
      left: 50%;
      transform: translate(-50%);
    }
  }

  &__status {
    .pomodoro__label--small {
      text-transform: uppercase;
    }
    margin-top: 3rem;
  }
}

//Theming

.dark-mode {
  .pomodoro {
    &__label {
      &--large {
        color: white;
      }
    }

    &__textarea {
      color: white !important;
    }
  }
}
