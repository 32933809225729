// .posts{

//     margin-top: 10rem;

//     }

// // }

// // .dark-mode .temporary{
// //     filter: invert(100);

// // }

// // .dark-mode .notion {

// //     img{
// //         filter: invert(100);
// //     }

// // }

// .notion {
//     // align-self: center;
//     // margin-top: 2rem;
//     // max-width: 50rem;
//     // min-height: 20rem;

//     // h2 {
//     //   font-size: 2rem;
//     //   margin-bottom: 0;
//     //   margin-top: 1rem;
//     // }

//     // a {
//     //   color: #000;
//     //   text-decoration: none;
//     // }
//     font-family:'DM Mono', monospace !important;

//     img{
//       width: 100%;
//       height: auto;
//     }

//     .post-content {
//       margin-top: 1rem;
//     }

//     .notion-text, .notion-link {
//       font-family: 'DM Sans', sans-serif;
//       font-size: 18px;
//       line-height: 35px;
//       margin-bottom: 1rem;
//     }

//     .notion-link {
//       background: $gray-light;
//       color: $gray;
//     }

//     .notion-image-inset {
//       cursor: pointer;
//       transition: box-shadow 0.3s ease-in-out;

//       &:hover {
//         box-shadow: 0 49.5px 70px -24.5px rgba(0, 0, 0, 0.3);
//       }
//     }

//     .notion-inline-code {
//       background: #c6cdf750;
//       color: #fd6467;
//       font-family: 'DM Mono', monospace;
//     }

//     .width-ref {
//       height: 1px;
//       width: 50rem;
//     }

//     .footer {
//       align-items: center;
//       display: flex;
//       flex-direction: column;
//       height: 15rem;
//       padding-top: 5rem;

//       a {
//         margin-bottom: .5rem;
//         width: 5rem;
//       }
//     }
//   }

//TEST

.posts {
  align-self: center;
  margin-top: 15rem;

  // .notion{
  //   font-size: 2rem;
  //   font-weight: 400;
  //   line-height: 1.8;
  // }

  .notion-text,
  .notion-link,
  ul,
  ol {
    font-family: 'DM Sans', sans-serif;
    //   font-size: 18px;
    //   line-height: 35px;
    //   margin-bottom: 1rem;

    font-size: 2rem;
    font-weight: 400;
    line-height: 1.8;
    margin-bottom: 1rem;
  }

  .heading-primary {
    font-size: 4rem;
    // text-transform: uppercase;
    text-align: left;
    font-weight: 200;
  }

  &__date {
    font-family: 'DM Sans', sans-serif;
    font-weight: 200;
  }

  .post {
    margin-top: 15rem;
  }

  .ghost-gap {
    margin-bottom: 60vh;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 0;
    margin-top: 5rem;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .post-content {
    margin-top: 1rem;
  }

  .notion-link {
    background: $gray-light;
    color: $gray;
  }

  .notion-image-inset {
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 49.5px 70px -24.5px rgba(0, 0, 0, 0.3);
      z-index: 999;
    }
  }

  .notion-inline-code {
    background: #c6cdf750;
    color: #fd6467;
    font-family: 'DM Mono', monospace;
  }

  .width-ref {
    height: 1px;
    width: 50rem;
  }

  .footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 15rem;
    padding-top: 5rem;

    a {
      margin-bottom: 0.5rem;
      width: 5rem;
    }
  }
}

.dark-mode .notion {
  color: inherit;

  img {
    filter: brightness(0.5);
  }
}
