.dark-mode {
  background-color: rgba(0, 0, 0, 0.938);
  color: rgba(255, 255, 255, 0.651);
  fill: rgba(255, 255, 255, 0.651);

  .ThemeSwitcher {
    color: yellow;
  }

  .sidebar {
    .title {
      color: #808080;

      &:hover {
        color: #fff;
      }
    }

    .is-highlighted {
      color: #fff;
    }
  }
}
