body {
  font-family: 'DM Mono', monospace;
  font-weight: 200;
  font-size: 2rem;
  line-height: 1.5;
  color: rgba(44, 43, 43, 0.966);
  padding: 2rem;
  transition: color 0.3s;
  transition: fill 0.3s;
  transition: background-color 0.3s;
}

.heading-primary {
  font-size: 2.3rem;
}
