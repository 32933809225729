@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(15rem);
  }

  100% {
    opacity: 1;
  }
}

// @keyframes slideFromRight{
//     0%{
//         opacity: 0;
//         transform: translateX(15rem)
//     }

//     100%{
//         opacity: 1;
//     }
// }

@keyframes fadeInAndUp {
  0% {
    opacity: 0;
    transform: translateY(4rem);
  }

  100% {
    opacity: 1;
  }
}

@keyframes glow {
  0% {
    color: $yellow;
  }

  50% {
    opacity: 0.5;
    color: inherit;
  }

  100% {
    color: $yellow;
  }
}
