::selection {
  background-color: burlywood;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  // position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}
