.fluff {
  // transition: all .3s;
}
.row {
  max-width: 65rem;
  margin: 0 auto;
  font-size: 2rem;

  &--blog {
    max-width: 100rem;
    margin: 0 auto;
    font-size: 1.6rem;
  }
}
