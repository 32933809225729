//Variables
$black: #000;
$gray: #6c757d;
$gray-light: #6c757d10;
$gray-light-opaque: #f6f6f7;
$gray-hover: #6c757d20;
$gray-mid: #6c757d99;
$green: #28a745;
$green-light: #28a74520;
$green-hover: #28a74530;
$green-mid: #28a74599;
$orange: #fd7e14;
$orange-light: #fd7e1420;
$orange-light-opaque: #ffefe2;
$orange-hover: #fd7e1430;
$orange-mid: #fd7e1499;
$yellow: #ffb300;
$yellow-light: #ffc10720;
$yellow-light-opaque: #fff7e0;
$yellow-hover: #ffc10730;
$yellow-mid: #ffc10799;
$blue: #4c75f2;
$blue-light: #4c75f210;
$blue-hover: #4c75f230;
$blue-light-opaque: #eff7ff;
$blue-mid: #4c75f299;
