.intro {
  margin-top: 16rem;
  margin-bottom: 4rem;

  &__buttons {
    margin-top: 4rem;
  }

  &__buttons > * {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

  &__opportunities {
    background-color: transparent;
    padding: 1rem;
    display: block;
    width: fit-content;
    margin-left: -1rem;
    &:hover,
    &:visited {
      background-color: $gray-hover;
      padding: 1rem;
      display: block;
      width: fit-content;
      transform: translateY(-0.3rem);
    }

    svg {
      animation-name: glow;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
  }
}
//SECTION SOCIALS

.socials {
  fill: inherit;
  animation-name: fadeInAndUp;
  animation-delay: 0.6s;
  animation-fill-mode: both;
  animation-duration: 0.6s;

  .social {
    svg {
      margin-bottom: -0.6rem;
    }
    margin-bottom: 0.5rem;
    &--linkedin {
      svg {
        fill: $blue;
        color: $blue;
      }
    }

    &--github {
      svg {
        margin-bottom: 0;
      }
    }
  }

  svg {
    display: inline;
    margin-right: 1rem;
  }
}
