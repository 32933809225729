.sidebar {
  // animation-name:slideFromLeft;
  // animation-duration: .3s;
  font-size: 1.9rem;
  font-weight: 400;

  color: $gray-mid;
  // background-color: red;

  display: flex;
  flex-direction: column;
  height: 100vh;
  // overflow-y: auto;
  padding-right: 2rem;
  position: fixed;
  right: 0;
  text-align: right;
  top: 7.5rem;
  z-index: 999;

  &:hover {
    color: $gray;
  }

  .title {
    cursor: pointer;
    transition: all 0.2s;
    display: inline-block;
    a {
      text-decoration: none;
      color: inherit;
      &:hover {
        color: inherit;
      }
    }

    &:hover {
      color: $black;
    }

    &.is-highlighted {
      color: $black;
    }
    &.is-highlighted .dark-mode {
      color: lighten($black, $amount: 50);
    }
  }
}

.sidebar-toggle {
  // position: absolute;

  // color: $gray;
  cursor: pointer;
  position: fixed;
  right: 2rem;
  top: 2rem;
  z-index: 10;
}
