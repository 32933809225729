.btn {
  &,
  &:link,
  &:visited {
    padding: 0.5rem 1rem;
    display: inline-block;
    cursor: pointer;
    color: black;
    text-decoration: none;

    animation-name: fadeInAndUp;
    animation-duration: 0.6s;

    &:active {
      transform: translateY(0.2rem);
    }
  }

  &--blue {
    background-color: $blue-mid;
    &:hover {
      background-color: $blue;
    }
  }
  &--orange {
    background-color: $orange-light;
    &:hover {
      background-color: $orange;
    }
  }
  &--green {
    background-color: $green-light;
    &:hover {
      background-color: $green;
    }
  }

  &--link {
    background-color: lighten($gray-hover, 20%);
    width: fit-content;
    padding: 0.3rem 0.5rem;
    &:hover {
      background-color: darken($gray-hover, 20%);
    }
  }
}

//dark mode
.dark-mode .btn {
  color: inherit;

  &--blue {
    background-color: $blue;

    &:hover {
      background-color: $blue-mid;
    }
  }

  &--orange {
    background-color: $orange;

    &:hover {
      background-color: $orange-light;
    }
  }

  &--green {
    background-color: $green;
    //    color: $gray-light-opaque;

    &:hover {
      background-color: $green-light;
    }
  }
}
